import React from 'react';
import clsx from 'clsx';
import { match } from 'path-to-regexp';
import { HiOutlineHashtag, HiOutlineHome, HiOutlineMail, HiOutlineShoppingCart } from 'react-icons/hi';
import {
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
  HiOutlineFolderOpen,
  HiOutlineLink,
  HiOutlineLockClosed,
} from 'react-icons/hi2';
import { Link, useLocation } from 'react-router-dom';
import { Sidebar as UISidebar } from '@/components/ui-parts/layout/sidebar';
import { SidebarContext } from '@/components/ui-parts/layout/sidebar/SidebarContext';
import { SidebarMenu, SidebarMenuItem } from '@/components/ui-parts/layout/sidebar/SidebarMenu';

export const Sidebar = React.memo(() => {
  const location = useLocation();

  return (
    <UISidebar defaultOpen={false}>
      <SidebarContext.Consumer>
        {({ open, handleOpen, handleClose }) => (
          <>
            <SidebarMenu>
              {open ? (
                <div onClick={handleClose}>
                  <div className="text-shade-dark-default text-r flex cursor-pointer items-center justify-end p-4 font-medium">
                    <HiOutlineChevronDoubleLeft size={24} className={clsx(['text-primary-dark-default'])} />
                  </div>
                </div>
              ) : (
                <div onClick={handleOpen}>
                  <div className="text-shade-dark-default text-r flex cursor-pointer items-center  p-4 font-medium">
                    <HiOutlineChevronDoubleRight size={24} className={clsx(['text-primary-dark-default'])} />
                  </div>
                </div>
              )}

              <Link to="/">
                <SidebarMenuItem
                  IconComponent={HiOutlineHome}
                  label="ホーム"
                  active={
                    !!match('/', {
                      decode: decodeURIComponent,
                    })(location.pathname)
                  }
                />
              </Link>

              <Link to="/estimate-requests/new">
                <SidebarMenuItem
                  IconComponent={HiOutlineHashtag}
                  label="見積依頼"
                  active={
                    !!match('/estimate-requests/new', {
                      decode: decodeURIComponent,
                    })(location.pathname)
                  }
                />
              </Link>

              <Link to="/projects">
                <SidebarMenuItem
                  IconComponent={HiOutlineFolderOpen}
                  label="案件管理"
                  active={
                    !!match('/projects(.*)', {
                      decode: decodeURIComponent,
                    })(location.pathname)
                  }
                />
              </Link>

              <Link to="/containerec">
                <SidebarMenuItem
                  IconComponent={HiOutlineShoppingCart}
                  label="コンテナEC"
                  active={
                    !!match('/containerec', {
                      decode: decodeURIComponent,
                    })(location.pathname)
                  }
                />
              </Link>

              <div className="mt-auto">
                {/* <hr className="border-shade-medium-default my-2 mx-4" /> */}

                <a href="https://willbox.jp/contact" target="_blank" rel="noreferrer">
                  <SidebarMenuItem IconComponent={HiOutlineMail} label="お問合せ" />
                </a>

                <a href="https://willbox.jp/privacy" target="_blank" rel="noreferrer">
                  <SidebarMenuItem IconComponent={HiOutlineLockClosed} label="プライバシーポリシー" />
                </a>

                <a href="https://willbox.jp/terms" target="_blank" rel="noreferrer">
                  <SidebarMenuItem IconComponent={HiOutlineLockClosed} label="利用規約" />
                </a>

                <a href="https://willbox.jp" target="_blank" rel="noreferrer">
                  <SidebarMenuItem IconComponent={HiOutlineLink} label="©2023Willbox,Inc." />
                </a>
              </div>
            </SidebarMenu>
          </>
        )}
      </SidebarContext.Consumer>
    </UISidebar>
  );
});

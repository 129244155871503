import React from 'react';
import clsx from 'clsx';
import { useSidebarContext } from './SidebarContext';

export type SidebarMenuItemProps = {
  IconComponent: React.ElementType;
  label: string | React.ReactNode; // NOTE: 一時的に文字列とコンポーネントを許容
  active?: boolean;
  className?: string;
};

export const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({
  IconComponent,
  label,
  className,
  active = false,
}) => {
  const { open } = useSidebarContext();
  return (
    <div
      // eslint-disable-next-line tailwindcss/classnames-order
      className={clsx([
        'flex cursor-pointer items-center p-4 text-r font-medium',
        active
          ? 'rounded-none border-0 border-l-4 border-solid border-l-[#9B5A28] bg-[#9B5A28]/10  text-shade-dark-default hover:opacity-70 text-[#9B5A28]'
          : 'text-shade-dark-default',
        className,
      ])}
    >
      <IconComponent size={24} className={clsx([open ? 'mr-4' : 'mr-0'])} />

      {open ? label : null}
    </div>
  );
};

export type SidebarMenuProps = {
  children: React.ReactNode;
};

export const SidebarMenu: React.FC<SidebarMenuProps> = ({ children }) => {
  return <nav className="hidden-scrollbar flex h-0 flex-1 flex-col overflow-y-auto pb-2">{children}</nav>;
};

import React, { PropsWithChildren } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Layout } from '@/components/layout';
import { Auth0UrqlProvider } from '@/components/urql';
import { AppState, Auth0Provider } from '@auth0/auth0-react';

const PrivateRouter = React.lazy(() => import('./private'));
const HomePage = React.lazy(() => import('@/pages/home'));
const LoginPage = React.lazy(() => import('@/pages/login'));
const ContainerECPage = React.lazy(() => import('@/pages/containerec'));
const ProjectListPage = React.lazy(() => import('@/pages/project'));
const ProjectDetailPage = React.lazy(() => import('@/pages/project/[projectId]'));
const TimelinePage = React.lazy(() => import('@/pages/project/[projectId]/contact-rooms/[contactRoomId]/timelines'));
const TimelineDetailPage = React.lazy(
  () => import('@/pages/project/[projectId]/contact-rooms/[contactRoomId]/timelines/[timelineId]'),
);
const NewEstimateRequestPage = React.lazy(() => import('@/pages/estimate-request/new'));
const NotFound = React.lazy(() => import('@/pages/notfound'));
const MaintenancePage = React.lazy(() => import('@/pages/maintenance'));

const domain = import.meta.env.VITE_AUTH0_DOMAIN as string;
const client = import.meta.env.VITE_AUTH0_CLIENT_ID as string;

const AuthProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    // If using a Hash Router, you need to use window.history.replaceState to
    // remove the `code` and `state` query parameters from the callback url.
    // window.history.replaceState({}, document.title, window.location.pathname);
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={client}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      // cacheLocation="memory"
    >
      {children}
    </Auth0Provider>
  );
};

export const Router = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Auth0UrqlProvider>
          <Routes>
            <Route element={<PrivateRouter />}>
              <Route element={<Layout />}>
                <Route path="/containerec" element={<ContainerECPage />} />

                <Route path="/" element={<HomePage />} />
                <Route path="/home" element={<Navigate replace to="/" />} />
                <Route path="/estimate_requests/new" element={<Navigate replace to="/estimate-requests/new" />} />
                <Route path="/estimate-requests/new" element={<NewEstimateRequestPage />} />
                <Route path="/projects" element={<ProjectListPage />} />
                <Route path="/projects/:projectId" element={<ProjectDetailPage />} />

                <Route
                  path="/projects/:projectId/contact_rooms/:contactRoomId/timelines/:timelineId"
                  element={<RedirectTimelines />}
                />

                <Route path="/projects/:projectId/contact-rooms/:contactRoomId/timelines" element={<TimelinePage />}>
                  <Route
                    path="/projects/:projectId/contact-rooms/:contactRoomId/timelines/:timelineId"
                    element={<TimelineDetailPage />}
                  />
                </Route>
              </Route>
              {/* duplicated  */}
              <Route path="/projects/:projectId/contact_rooms/:contactRoomId" element={<RedirectTimelines />} />
              <Route path="/projects/:projectId/contact-rooms/:contactRoomId" element={<RedirectTimelines />} />
              <Route
                path="/projects/:projectId/contact_rooms/:contactRoomId/timelines"
                element={<RedirectTimelines />}
              />
            </Route>

            <Route path="maintenance" element={<MaintenancePage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Auth0UrqlProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

const RedirectTimelines = () => {
  const params = useParams<{
    timelineId: 'timelineId';
    contactRoomId: 'contactRoomId';
    projectId: 'projectId';
  }>() as {
    timelineId: string;
    contactRoomId: string;
    projectId: string;
  };

  if (params.timelineId) {
    return (
      <Navigate
        replace
        to={`/projects/${params.projectId}/contact-rooms/${params.contactRoomId}/timelines/${params.timelineId}`}
      />
    );
  }

  return <Navigate replace to={`/projects/${params.projectId}/contact-rooms/${params.contactRoomId}/timelines`} />;
};

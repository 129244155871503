import React, { Suspense } from 'react';
import { PropsWithChildren } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Sidebar } from '@/components/layout/sidebar';
import { Layout as UILayout } from '@/components/ui-parts/layout';

// eslint-disable-next-line @typescript-eslint/ban-types
export const Layout = ({ children }: PropsWithChildren<{}>) => {
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    if (location.pathname !== '/maintenance' && import.meta.env.VITE_MAINTENANCE === 'true') {
      navigate('/maintenance');
      return;
    }
  }, []);
  return (
    <Suspense>
      <UILayout SidebarComponent={Sidebar}>{children ? children : <Outlet />}</UILayout>
    </Suspense>
  );
};

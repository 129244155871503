import React, { PropsWithChildren, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '@/components/layout/header';

type Props = PropsWithChildren<{
  SidebarComponent: React.ElementType;
}>;

export const Layout = ({ SidebarComponent }: Props) => {
  return (
    <div className="relative flex h-screen flex-col overflow-hidden bg-[#F9F7F1]">
      <Suspense>
        <Header />
      </Suspense>
      <div className="flex flex-1 flex-row overflow-auto">
        <SidebarComponent />
        <React.Suspense>
          <Outlet />
        </React.Suspense>
      </div>
    </div>
  );
};
